import { twilio } from "core/UrlEndpoints";
import { fetchDataJson } from "../FetchApi";

class TwilioService {
  async token() {
    return fetchDataJson(`${twilio}/voice_auth_token`, {
      method: "POST",
    });
  }
}

export default new TwilioService();
