import React, { createContext, useState, useRef, useEffect, } from "react";
import UserLogService from "core/api/UserLogService";
import { useAuth0 } from "react-auth0-spa";

export const UserContext = createContext({ userData: { status: "Offline" }, setUserData: () => { }, setUserStatus: () => { }, prevUserStatus: "Offline", userStatusRef: null })

export const UserProvider = ({ children }) => {
    const [userData, setUserData] = useState({ status: "Offline" });
    const [prevUserStatus, setPrevUserStatus] = useState("Offline")
    const userStatusRef = useRef(userData.status)
    const { user } = useAuth0()

    const setUserStatus = (status) => {
        setUserData((userData) => { setPrevUserStatus(userData.status); return { ...userData, status: status } });
    }

    useEffect(() => {
        userStatusRef.current = userData.status
    }, [userData.status])

    useEffect(() => {
        async function fetchData() {
            const activeUsers = await UserLogService.getActiveUsers(new Date().toISOString())

            if (activeUsers.model?.find(userLog => userLog.userEmail == user.email)) {
                setUserData({ ...userData, status: "Online" })
            }
        }

        fetchData();
    }, [])


    return (
        <UserContext.Provider value={{ userData, setUserData, setUserStatus, prevUserStatus, userStatusRef }}>
            {children}
        </UserContext.Provider>
    )
}