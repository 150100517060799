
import React, { createContext, useState, useRef, useEffect, } from "react";

export const CallContext = createContext({ calls: [], setCalls: () => { }, devices: null, callsRef: null })

export const CallProvider = ({ children }) => {
    const [calls, setCalls] = useState([]);
    const devices = useRef(new Map())
    const callsRef = useRef(calls)

    useEffect(() => {
        callsRef.current = calls
    }, [calls])

    return (
        <CallContext.Provider value={{ calls, setCalls, devices, callsRef }}>
            {children}
        </CallContext.Provider>
    )
}