import { callLog } from "core/UrlEndpoints";
import CrudService from "./CrudService";
import { fetchDataJson } from "../FetchApi";

class CallLogService extends CrudService {
    constructor() {
        super(callLog)
    }

    async GetByCallID(id) {
        return fetchDataJson(`${callLog}/GetByCallID/${id}`, {
            method: "GET",
        });
    }


}

export default new CallLogService();