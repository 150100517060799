import React, { useContext, useState } from "react";
import { Button, Modal, ModalBody, ModalFooter } from "reactstrap";
import { useHistory } from "react-router-dom";

const CallComponent = ({ callData, acceptCall, rejectCall, endCall, muteCall, getActiveCall, acceptIncomingCall, holdCall, answerIncomingCall }) => {
  // const { callState, callOnHook, error } = useTwilioCall(callData.call);
  const history = useHistory();
  const [switchCallPopup, setSwitchCallPopup] = useState({visible: false, activeCall: null})

  const answerCallClicked = async () => {

    const activeCallData = getActiveCall();

    if (activeCallData) {
      setSwitchCallPopup({ visible: true, activeCall: activeCallData.call })
    }else{
      answerIncomingCall(callData)
    }
   
  }

  const putActiveOnHoldAndAnswerCall = () => {
    holdCall(switchCallPopup.activeCall, true);
    //putallActiveCallsOnHold()

    answerIncomingCall(callData);
  }


  const putCallOnHold = async () => {
    if (callData.callStatus == "OnHold") {
      //reroute back to report when take off hold
      const id = callData.elevatorReportID
      if (id && !history.location.pathname.includes(`call/${id}`)) {
        history.push(`call/${id}`)
      }

      holdCall(callData.call, false)

    } else {
      holdCall(callData.call, true)
    }

  }



  return (
    <>

      {/* {error && <p>Error: {error}</p>} */}

      <div style={{ display: "flex", flexDirection: "row", alignItems: "baseline" }}>
        <p style={{ paddingRight: 10, fontWeight: "bold" }}>{callData.call.parameters?.From ? callData.call.parameters?.From : callData.call.customParameters?.get("To")}</p>


        {callData.callStatus == "Incoming" && (<>
          <Button color="success" className="btn-fab btn-icon btn-round" onClick={() => answerCallClicked()}  >
            <i className="las la-phone-volume" style={{ fontSize: 25 }}></i>
          </Button>
          <Button color="danger" className="btn-fab btn-icon btn-round" onClick={() => rejectCall(callData.call, callData.callLogID)} >
            <i className="las la-phone-volume" style={{ fontSize: 25 }}></i>
          </Button>  </>
        )}

        {(callData.callStatus == "Active" || callData.callStatus == "OnHold" || callData.callStatus == "Muted") && (

          <> 
            {callData.callStatus != "OnHold" &&
              <Button color={"info"} className="btn-fab btn-icon btn-round" onClick={() => muteCall(callData.call, callData.callStatus == "Muted" ? false : true)} style={{ marginRight: 5 }}>
                <i className={callData.callStatus == "Muted" ? "las la-microphone-slash" : "las la-microphone"} style={{ fontSize: 25 }}></i>
            </Button>
            }
            <Button color={"success"} className="btn-fab btn-icon btn-round" onClick={() => putCallOnHold()} style={{ marginRight: 5 }}>
              <i className={callData.callStatus == "OnHold" ? "las la-play" : "las la-pause"} style={{ fontSize: 25 }}></i>
            </Button>
            <Button color="danger" className="btn-fab btn-icon btn-round" onClick={() => endCall(callData.call, callData.callLogID)} >
              <i className="las la-phone-volume" style={{ fontSize: 25 }}></i>
            </Button>
        </>
        )}


        {callData.callStatus === "Outgoing" && <p>Dialing...</p>}
      </div>


        <Modal isOpen={switchCallPopup.visible} centered={true} >
                <ModalBody style={{ justifyContent: "center", alignContent: "center", textAlign: "center" }} >
                    <i className="las la-phone" style={{ fontSize: 40 }}></i>
          <p style={{ fontSize: 16 }}>{`Do you want to answer this call and put ${switchCallPopup?.activeCall?.parameters?.From ? switchCallPopup?.activeCall?.parameters?.From : switchCallPopup?.activeCall?.customParameters?.get("To")} on hold?`}</p>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={() => { setSwitchCallPopup({ visible: false, activeCall: null }) }} style={{ margin: "auto", display: "block" }} >Cancel</Button>
                    <Button color="primary" onClick={() => {putActiveOnHoldAndAnswerCall(); setSwitchCallPopup({ visible: false, activeCall: null }) }} style={{ margin: "auto", display: "block" }} >Answer Call</Button>
                </ModalFooter>
      </Modal>

    </>
  );
};

export default CallComponent;
