import { AudioProcessor } from "@twilio/voice-sdk";

let audioContext: AudioContext | undefined;

export class BackgroundAudioProcessor implements AudioProcessor {

    private audioContext: AudioContext
    private background!: MediaElementAudioSourceNode;
    private destination!: MediaStreamAudioDestinationNode;

    constructor() {
        if (!audioContext) {
            audioContext = new AudioContext();
        }
        this.audioContext = audioContext;
    }

    async createProcessedStream(stream: MediaStream): Promise<MediaStream> {
        // Create the source node
        const audioEl = new Audio('/HoldMessage.mp3');
        audioEl.loop = false
        audioEl.addEventListener('canplaythrough', () => audioEl.play());

        audioEl.addEventListener('ended', () => {
            setTimeout(() => {
                audioEl.play()
            }, 8000)
        })

        this.background = this.audioContext.createMediaElementSource(audioEl);

        // Create the destination node and connect the source node
        this.destination = this.audioContext.createMediaStreamDestination();
        this.background.connect(this.destination);

        // Return the resulting MediaStream
        return this.destination.stream;
    }

    async destroyProcessedStream(stream: MediaStream): Promise<void> {
        // Cleanup
        this.background.disconnect();
        this.destination.disconnect();
    }
}