
import React, { createContext, useState, useRef, useCallback, useEffect, useContext } from "react";
import { Device } from "@twilio/voice-sdk";
import TwilioService from "core/api/TwilioService";
import { useTwilioDevice } from "components/Phone/V1/useTwilioDevice";
import CallService from "core/api/CallService";
import CallLogService from "core/api/CallLogService";
import { UserContext } from "./UserContext";

export const MainDeviceContext = createContext({ deviceData: { deviceStatus: "loading", deviceError: null }, setDeviceData: () => { }, deviceRef: null })

export const MainDeviceProvider = ({ children }) => {
    const [deviceData, setDeviceData] = useState({ deviceStatus: "loading", deviceError: null });
    const deviceRef = useRef(null)

    const { addCall, endCall } = useTwilioDevice()
    const { userData, prevUserStatus } = useContext(UserContext)

    const initializeDevice = useCallback(async () => {
        try {
            const response = await TwilioService.token();
            const twilioDevice = new Device(response.token, {
                codecPreferences: ["opus", "pcmu"],
                allowIncomingWhileBusy: true,
            });

            // Set up event handlers for the Twilio Device
            twilioDevice.on("ready", () => setDeviceData({ ...deviceData, deviceStatus: "ready" }));
            twilioDevice.on("error", (err) => {
                setDeviceData({ ...deviceData, deviceError: err.message, deviceStatus: "error" });
            });
            twilioDevice.on("incoming", async (call) => {                

                call.on("cancel", () => {
                    console.log("The call has been canceled. Incoming");
                    endCall(call);
                });
                call.on("disconnect", (call) => {
                    console.log("The call has been disconnected.");
                    endCall(call);
                });
                call.on("error", (error) => {
                    console.log("An error has occurred: ", error);
                    if (error.name == "AcquisitionFailedError") {
                        alert("Call Failed. Make sure you have a microphone and sound connected and then try again.")
                    }
                });
                call.on("reject", () => {
                    console.log("The call was rejected.");
                });


                const callID = call.parameters.CallSid;

                const elevatorReport = await CallService.GetByExternalCallID(callID)
                const callLog = await CallLogService.GetByCallID(callID)

                addCall({ call, elevatorReportID: elevatorReport?.id, callStatus: "Incoming", callLogID: callLog?.id });

            });
            twilioDevice.on("registered", () => {
                setDeviceData({ ...deviceData, deviceStatus: "registered" })
            });
            twilioDevice.on("registering", () => {
                setDeviceData({ ...deviceData, deviceStatus: "registering" })
            });
            twilioDevice.on("unregistered", () => {
                setDeviceData({ ...deviceData, deviceStatus: "unregistered" })
            });
            twilioDevice.on("tokenWillExpire", async () => {
                const response = await TwilioService.token();

                deviceRef.current.updateToken(response.token);
            });

            deviceRef.current = twilioDevice;

            twilioDevice.register();
        } catch (err) {
            setDeviceData({ ...deviceData, deviceError: "Failed to initialize Twilio Device", deviceStatus: "error" });
        }
    }, []);



    useEffect(() => {
        //Only update device if switching to or from offline 
        if (prevUserStatus == "Offline" || userData.status === "Offline") {

            if (userData.status == "Online") { 
                initializeDevice();
            } else {
                if (deviceRef.current) {
                    deviceRef.current.destroy();
                }
            }
        }



    }, [initializeDevice, userData.status]);

    useEffect(() => {
        // Cleanup function to destroy the device instance
        return () => {
            if (deviceRef.current) {
                deviceRef.current.destroy();
            }
        };
    }, [])


    return (
        <MainDeviceContext.Provider value={{ deviceData, setDeviceData, deviceRef }}>
            {children}
        </MainDeviceContext.Provider>
    )
}