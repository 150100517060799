import React from "react";
import Table from "../Shared/Table";

import {
    Card,
    CardBody,
    CardHeader,
    CardTitle,
    Row,
    Col,
} from "reactstrap";

import Moment from "react-moment";
import { userLog } from "core/UrlEndpoints";
import PanelHeader from "components/PanelHeader/PanelHeader.jsx";

const UserLogList = () => {

    return (
        <>
            <PanelHeader
                content={
                    <div className="header text-center">
                        <h2 className="title">User Status Logs</h2>
                    </div>
                }
            />
            <div className="content">
                <Row>
                    <Col xs={12} md={12}>
                        <Card>
                            <CardHeader>
                                <CardTitle tag="h4">User Status Logs</CardTitle>

                            </CardHeader>
                            <CardBody>
                                <Table
                                    url={userLog}
                                    orderBy={{ field: "LogDate", desc: true }}
                                    columns={[

                                        {
                                            header: "User Email",
                                            accessorKey: "userEmail",
                                        },
                                        {
                                            header: "Status",
                                            accessorKey: "status",
                                        },
                                        {
                                            header: "Log Date",
                                            accessorKey: "logDate",
                                            accessorFn: row => { return new Date(row.logDate) },
                                            filterFn: 'filterDate',
                                            cell: ({ row }) => (
                                                <>
                                                    {row.original.logDate == null ? (
                                                        ""
                                                    ) : (
                                                        <Moment>{row.original.logDate}</Moment>
                                                    )}
                                                </>
                                            ),

                                        },


                                    ]}
                                />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
        </>
    );
};

export default UserLogList;
