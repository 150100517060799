import React, { useContext } from "react";
import { useAuth0 } from "react-auth0-spa";
import {
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem
} from "reactstrap";
import UserLogService from "core/api/UserLogService";
import { UserContext } from "context/UserContext";

const ProfileButton = () => {
    const { isAuthenticated, loginWithRedirect, logout, user } = useAuth0();
    const { userData } = useContext(UserContext)

    const logOffUser = async () => {
        if (userData.status != "Offline") {
            await UserLogService.add({ UserEmail: user.email, Status: "Offline", LogDate: new Date() })
        }

    }

    return (
        <UncontrolledDropdown group>
            <DropdownToggle caret>
                <i className="now-ui-icons users_single-02" />
                <p>
                    <span className="d-lg-none d-md-block">Account</span>
                </p>
            </DropdownToggle>
            <DropdownMenu>
                {isAuthenticated && <DropdownItem onClick={() => { logOffUser(); logout() }}>Log out</DropdownItem>}
            </DropdownMenu>
        </UncontrolledDropdown>
    );
};

export default ProfileButton;