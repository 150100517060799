import { useContext, useEffect, useState } from "react";
import { useTwilioDevice } from "./useTwilioDevice";
import CallComponent from "./CallComponent"; // Import the CallComponent
import { MainDeviceContext } from "context/MainDeviceContext";
import { Card, Row, Col, CardHeader, CardBody, CardTitle, Button, FormGroup, Label, Input, Modal, ModalBody, ModalFooter } from "reactstrap";
import { useAuth0 } from "react-auth0-spa";

const SoftphoneV1 = () => {
  const {
    makeOutgoingCall,
    acceptCall,
    rejectCall,
    endCall,
    muteCall,
    getActiveCall,
    holdCall
  } = useTwilioDevice();
  const [phoneNumber, setPhoneNumber] = useState("+1");
  const [makeCallPopup, setMakeCallPopup] = useState({ visible: false, activeCall: null })

  const { deviceData } = useContext(MainDeviceContext)
  const { user } = useAuth0();

  const verifyOutgoingCall = () => {
    //also popup or message if invalid phone number entered

    const activeCallData = getActiveCall()
    if (activeCallData) {
      setMakeCallPopup({ visible: true, activeCall: activeCallData.call })

    } else {
      makeOutgoingCall(phoneNumber, user.email)
    }
  }

  const putActiveOnHoldAndMakeCall = () => {
    holdCall(makeCallPopup.activeCall, true);

    makeOutgoingCall(phoneNumber, user.email)
  }


  return (
    <div style={{ marginTop: -30 }}>
      <Row>
        <Col xs={12} md={12}>
          <Card>
            <CardHeader>
              <CardTitle tag="h4">Softphone</CardTitle>
            </CardHeader>
            <CardBody>

              {deviceData.deviceEror && <p>Error: {deviceData.deviceError}</p>}
              
              <p>Device Status: {deviceData.deviceStatus.charAt(0).toUpperCase() + deviceData.deviceStatus.slice(1)}</p>

              {(deviceData.deviceStatus === "ready" || deviceData.deviceStatus === "registered") && (
        <>
          <label>
            Phone Number:
                    <input className="form-control" style={{ fontSize: 16 }}
                      type="tel" pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
            />
          </label>

                  <Button color="primary" onClick={() => verifyOutgoingCall()} style={{ marginLeft: 10 }}>
                    Make Outgoing Call
                  </Button>
        </>
      )}
            </CardBody>
          </Card>
        </Col>
      </Row>

     
      <Modal isOpen={makeCallPopup.visible} centered={true} >
        <ModalBody style={{ justifyContent: "center", alignContent: "center", textAlign: "center" }} >
          <i className="las la-phone" style={{ fontSize: 40 }}></i>
          <p style={{ fontSize: 16 }}>{`Do you want to place this call and put ${makeCallPopup?.activeCall?.parameters?.From ? makeCallPopup?.activeCall?.parameters?.From : makeCallPopup?.activeCall?.customParameters?.get("To")} on hold?`}</p>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={() => { setMakeCallPopup({ visible: false, activeCall: null }) }} style={{ margin: "auto", display: "block" }} >Cancel</Button>
          <Button color="primary" onClick={() => { putActiveOnHoldAndMakeCall(); setMakeCallPopup({ visible: false, activeCall: null }) }} style={{ margin: "auto", display: "block" }} >Make Call</Button>
        </ModalFooter>
      </Modal>

    </div>


  );
};

export default SoftphoneV1;
