import React from "react";
import { Router, Switch, Redirect } from "react-router-dom";
import { useAuth0 } from "./react-auth0-spa";
import history from "./utils/history";
import PrivateRoute from "./components/PrivateRoute";

import "bootstrap/dist/css/bootstrap.css";
import "assets/css/now-ui-dashboard.css";
import "assets/css/demo.css";
import "react-image-lightbox/style.css";
import "react-edit-text/dist/index.css";
import 'survey-core/defaultV2.min.css';
import 'line-awesome/dist/line-awesome/css/line-awesome.min.css';
import 'assets/css/custom-css.css'

import AdminLayout from "layouts/Admin.jsx";
import Loading from "components/Loading";

import { CallProvider } from "context/CallContex";
import { MainDeviceProvider } from "context/MainDeviceContext";
import { UserProvider } from "context/UserContext";

import CallPopup from "components/Phone/CallPopup";

import {
  Chart as ChartJS,
  LineController,
  LineElement,
  PointElement,
  LinearScale,
  CategoryScale,
  Title,
} from "chart.js";


ChartJS.register(
  LineController,
  LineElement,
  PointElement,
  LinearScale,
  CategoryScale,
  Title
);

const App = () => {
  const { loading } = useAuth0();

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <UserProvider>
        <CallProvider>
          <MainDeviceProvider>

            <Router history={history}>
              <CallPopup />
              <Switch>
                <PrivateRoute
                  path="/admin"
                  component={(props) => {
                    return <AdminLayout {...props} />;
                  }}
                />
                <Redirect to="/admin/dashboard" />
              </Switch>
            </Router>

          </MainDeviceProvider>
        </CallProvider>
      </UserProvider>
    </>
  );
};

export default App;
