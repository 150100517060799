import React, { useContext, useEffect, useState } from "react";
import { Button, List, Badge } from "reactstrap";
import { useAuth0 } from "react-auth0-spa";
import UserLogService from "core/api/UserLogService";
import { UserContext } from "context/UserContext";
import { MainDeviceContext } from "context/MainDeviceContext";
import Switch from "./Switch";

export default function CallCenterStatus() {
    const [onlineAgents, setOnlineAgents] = useState([])
    const [showOnlineAgents, setShowOnlineAgents] = useState(false)

    const { userData, setUserStatus } = useContext(UserContext)
    const { deviceData } = useContext(MainDeviceContext)
    const { user } = useAuth0();

    const toggleOnlineStatus = async () => {
        let userStatusLog = { UserEmail: user.email, LogDate: new Date() };
        if (userData.status == "Offline") {
            userStatusLog.Status = "Online"

        } else {
            userStatusLog.Status = "Offline"
        }

        await UserLogService.add(userStatusLog)

        setUserStatus(userStatusLog.Status)
    }


    useEffect(() => {

        async function fetchData() {
            const onlineUsers = await UserLogService.getActiveUsers(new Date().toISOString())
            setOnlineAgents(onlineUsers.model)
        }

        fetchData();
        const interval = setInterval(() => {
            fetchData()
        }, 15000)

        return () => { clearInterval(interval) }

    }, [])


    return (
        <div>
            <div style={{ backgroundColor: userData.status == "Offline" ? "red" : "green", width: 350, height: 100, padding: 10, position: "absolute", right: 150, top: 40 }}>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <div className="title" style={{ fontSize: 16 }}>Call Center Status</div>

                    <Switch value={userData.status != "Offline"} onChange={() => toggleOnlineStatus()} trueText={"Online"} FalseText={"Offline"} />

                </div>

                <div id="numOnlineAgents" onMouseEnter={() => setShowOnlineAgents(true)} onMouseLeave={() => setShowOnlineAgents(false)}>{`Online Agents: ${onlineAgents.length}`}</div>

                <div> Device Status: {deviceData.deviceStatus.charAt(0).toUpperCase() + deviceData.deviceStatus.slice(1)}</div>

                <div>My Status: {userData.status}</div>

            </div>

            {(showOnlineAgents && onlineAgents.length > 0) &&
                <div style={{ backgroundColor: "white", position: "absolute", right: 510, top: 40, padding: 10, color: "black" }}>
                    <div style={{ color: "black", fontWeight: "bold", textAlign: "center" }}>Online Agents</div>
                    <List type="unstyled" style={{ marginLeft: 5, marginRight: 5 }}>
                        {onlineAgents?.map((agent, index) => (
                            <li key={index}>
                                {agent.userEmail}
                                {agent.status == "Busy" && <Badge color="info">Busy</Badge>}
                                {agent.status == "Online" && <Badge color="success">Ready</Badge>}

                            </li>
                        ))}
                    </List>
                </div>

            }

        </div>

    )
}