import React from "react";
import Table from "../Shared/Table";

import {
    Card,
    CardBody,
    CardHeader,
    CardTitle,
    Row,
    Col,
} from "reactstrap";

import Moment from "react-moment";
import { callLog } from "core/UrlEndpoints";
import PanelHeader from "components/PanelHeader/PanelHeader.jsx";

const CallLogList = () => {

    return (
        <>
            <PanelHeader
                content={
                    <div className="header text-center">
                        <h2 className="title">Call Logs</h2>
                    </div>
                }
            />
            <div className="content">
                <Row>
                    <Col xs={12} md={12}>
                        <Card>
                            <CardHeader>
                                <CardTitle tag="h4">Call Logs</CardTitle>

                            </CardHeader>
                            <CardBody>
                                <Table
                                    url={callLog}
                                    orderBy={{ field: "DateTimeProcessed", desc: true }}
                                    columns={[
                                        {
                                            header: "Call Direction",
                                            accessorKey: "callDirection",
                                            cell: ({ row }) => (
                                                <>{row.original.callDirection.toUpperCase()}</>
                                            ),
                                        },
                                        {
                                            header: "Phone Number",
                                            accessorKey: "phoneNumber",
                                        },
                                        {
                                            header: "Agent",
                                            accessorKey: "agentEmail",
                                        },

                                        {
                                            header: "Date Processed",
                                            accessorKey: "dateTimeProcessed",
                                            accessorFn: row => { return new Date(row.dateTimeProcessed) },
                                            filterFn: 'filterDate',
                                            cell: ({ row }) => (
                                                <>
                                                    {row.original.dateTimeProcessed == null ? (
                                                        ""
                                                    ) : (
                                                        <Moment>{row.original.dateTimeProcessed}</Moment>
                                                    )}
                                                </>
                                            ),

                                        },
                                        {
                                            header: "Date Answered",
                                            accessorKey: "dateTimeAnswered",
                                            accessorFn: row => { return new Date(row.dateTimeAnswered) },
                                            filterFn: 'filterDate',
                                            cell: ({ row }) => (
                                                <>
                                                    {row.original.dateTimeAnswered == null || row.original.dateTimeAnswered == "0001-01-01T00:00:00Z" ? (
                                                        ""
                                                    ) : (
                                                        <Moment>{row.original.dateTimeAnswered}</Moment>
                                                    )}
                                                </>
                                            ),

                                        },
                                        {
                                            header: "Date Ended",
                                            accessorKey: "dateTimeEnded",
                                            accessorFn: row => { return new Date(row.dateTimeEnded) },
                                            filterFn: 'filterDate',
                                            cell: ({ row }) => (
                                                <>
                                                    {row.original.dateTimeEnded == null || row.original.dateTimeEnded == "0001-01-01T00:00:00Z" ? (
                                                        ""
                                                    ) : (
                                                        <Moment>{row.original.dateTimeEnded}</Moment>
                                                    )}
                                                </>
                                            ),

                                        },

                                        {
                                            header: "Call Minutes",
                                            id: "callLength",
                                            accessorFn: row => {
                                                if (row.dateTimeEnded != "0001-01-01T00:00:00Z" && row.dateTimeAnswered != "0001-01-01T00:00:00Z") {
                                                    let diffMilliseconds = new Date(row.dateTimeEnded) - new Date(row.dateTimeAnswered)
                                                    return Math.round(diffMilliseconds / (1000 * 60))

                                                } else {
                                                    return 0
                                                }

                                            }
                                        },

                                    ]}
                                />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
        </>
    );
};

export default CallLogList;
