import React, { useContext, useState } from "react";
import { Button, Card, ListGroup, ListGroupItem, Modal, ModalBody, ModalFooter } from "reactstrap";
import { CallContext } from "context/CallContex";
import CallComponent from "./V1/CallComponent";
import { useTwilioDevice } from "./V1/useTwilioDevice";
import { useAuth0 } from "react-auth0-spa";
import { useHistory } from "react-router-dom";
import CallService from "core/api/CallService";
import { UserContext } from "context/UserContext";
import UserLogService from "core/api/UserLogService";

export default function CallPopup() {

    const { calls, setCalls } = useContext(CallContext)
    const { userData, setUserStatus } = useContext(UserContext)
    const { user } = useAuth0();
    const history = useHistory();

    const {
        makeOutgoingCall,
        acceptCall,
        rejectCall,
        endCall,
        muteCall,
        getActiveCall,
        acceptIncomingCall,
        holdCall
    } = useTwilioDevice();

    const currentCalls = calls.filter(call => call.callStatus == "Active" || call.callStatus == "OnHold" || call.callStatus == "Muted")
    const incomingCalls = calls.filter(call => call.callStatus == "Incoming" || call.callStatus == "Outgoing")


    const [chooseCallReportPopup, setChooseCallReportPopup] = useState({ visible: false, newReport: null, oldReport: null })

    const answerIncomingCall = async (callData) => {

        const devices = await navigator.mediaDevices.enumerateDevices();
        const audioInputDevices = devices.filter(device => device.kind === 'audioinput');

        if (audioInputDevices.length === 0) {
            alert('No audio input device found. Please connect a microphone and try again.');
        } else {


            await acceptIncomingCall(callData, user.email)

            if (userData.status == "Online") {
                await UserLogService.add({ UserEmail: user.email, Status: "Busy", LogDate: new Date() })
                setUserStatus("Busy")
            }

            const id = callData.elevatorReportID

            if (id) {
                const newCallData = await CallService.get(id);
                let otherReports = await CallService.getCurrentReportsForElevator(newCallData?.elevatorId)

                otherReports = otherReports.model.filter(call => call.id != id && call.userEmail == user.email)

                if (!otherReports || otherReports.length <= 0) {
                    await openCallReport(newCallData)
                } else {
                    setChooseCallReportPopup({ visible: true, newReport: newCallData, oldReport: otherReports[0] })
                }

            }
        }
    }

    const openCallReport = async (data) => {
        if (data.status == "received") {
            await CallService.update({ ...data, status: "answered", UserEmail: user.email, DateTimeAnswered: new Date() })
        }


        if (!history.location.pathname.includes(`call/${data.id}`)) {
            history.push(`call/${data.id}`)
        }
    }

    const openReport = async (type) => {

        if (type == "new") {
            openCallReport(chooseCallReportPopup.newReport)
        } else {
            //update call elevator report id
            setCalls((prevCalls) => {
                return prevCalls.map((c) => c.call.parameters.CallSid == chooseCallReportPopup.newReport.externalCallId ? { ...c, elevatorReportID: chooseCallReportPopup.oldReport.id } : c)

            });

            //update new report so only current user will see this report and could terminate it if doesnt end up using it
            await CallService.update({ ...chooseCallReportPopup.newReport, status: "answered", UserEmail: user.email, DateTimeAnswered: new Date() })

            openCallReport(chooseCallReportPopup.oldReport)
        }
    }

    return (
        <>
            {calls.length > 0 &&
                <Card style={{ width: 300, backgroundColor: "white", position: "fixed", bottom: 40, right: 20, display: "flex", zIndex: 1000, paddingTop:10 }}>

                    {currentCalls.length > 0 &&
                        <>
                            <div className="title" style={{ fontSize: 16, textAlign: "Center", }}>Current Calls:</div>
                            <ListGroup flush>
                                {currentCalls.map((callData, index) => (
                                    <ListGroupItem key={callData.call.parameters.CallSid} >
                                        <CallComponent
                                            key={index}
                                            callData={callData}
                                            acceptCall={acceptCall}
                                            rejectCall={rejectCall}
                                            endCall={endCall}
                                            muteCall={muteCall}
                                            getActiveCall={getActiveCall}
                                            acceptIncomingCall={acceptIncomingCall}
                                            holdCall={holdCall}
                                            answerIncomingCall={answerIncomingCall}
                                        />
                                    </ListGroupItem>
                                ))}

                            </ListGroup>
                        </>
                    }
                    {incomingCalls.length > 0 && 

                        <>

                        <div className="title" style={{ fontSize: 16, textAlign: "Center", paddingTop: currentCalls.length > 0 ? 5 : 0 }}>Incoming Calls:</div>
                        <ListGroup flush>
                            {incomingCalls.map((callData, index) => (
                                <ListGroupItem key={callData.call.parameters.CallSid} >
                                    <CallComponent
                                        key={index}
                                        callData={callData}
                                        acceptCall={acceptCall}
                                        rejectCall={rejectCall}
                                        endCall={endCall}
                                        muteCall={muteCall}
                                        getActiveCall={getActiveCall}
                                        acceptIncomingCall={acceptIncomingCall}
                                        holdCall={holdCall}
                                        answerIncomingCall={answerIncomingCall}
                                    />
                                </ListGroupItem>
                            ))}

                        </ListGroup>
                        </>
                    }
                </Card>
              } 

            <Modal isOpen={chooseCallReportPopup.visible} centered={true} >
                <ModalBody style={{ justifyContent: "center", alignContent: "center", textAlign: "center" }} >
                    < div className="title" style={{ fontSize: 18 }}>There is an open report for this Elevator</div>
                    <p style={{ fontSize: 16 }}>Do you want to open a new report or continue the open one?</p>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={() => { openReport("old"); setChooseCallReportPopup({ visible: false, newReport: null, oldReport: null }) }} style={{ margin: "auto", display: "block" }} >Continue Open Report</Button>
                    <Button color="primary" onClick={() => { openReport("new"); setChooseCallReportPopup({ visible: false, newReport: null, oldReport: null }) }} style={{ margin: "auto", display: "block" }} >New Report</Button>
                </ModalFooter>
            </Modal>
        </>


    )
}

