import { useContext } from "react";
import { Device } from "@twilio/voice-sdk";
import TwilioService from "core/api/TwilioService";
import { CallContext } from "context/CallContex";
import { BackgroundAudioProcessor } from "../AudioProcessor.tsx";
import CallLogService from "core/api/CallLogService.js";
import UserLogService from "core/api/UserLogService.js";
import { UserContext } from "context/UserContext.js";
import { useAuth0 } from "react-auth0-spa.js";

export const useTwilioDevice = () => {

  const { calls, setCalls, devices, callsRef } = useContext(CallContext)
  const { setUserStatus, userStatusRef } = useContext(UserContext)
  const { user } = useAuth0()


  const addCall = (call) => {
    setCalls((prevCalls) => { return [...prevCalls, call] });
  };

  const removeCall = (call) => {
    setCalls((prevCalls) => { return prevCalls.filter((c) => c.call !== call) });
  };


  const makeOutgoingCall = async (to, agentEmail) => {
    try {
      const response = await TwilioService.token();
      const twilioDevice = new Device(response.token, {
        codecPreferences: ["opus", "pcmu"],
      });

      const params = {
        To: to,
        callingDeviceIdentity: "call_center_agent",
      };

      const call = await twilioDevice.connect({ params });

      const processor = new BackgroundAudioProcessor()

      var callLog

      call.on("cancel", () => {
        console.log("The call has been canceled.");
        endCall(call, callLog?.id);  
        updateUserStatusAfterEndCall(call)
        cleanDevice(twilioDevice, processor, call)
      });
      call.on("disconnect", (call) => {
        console.log("The call has been disconnected.");
        endCall(call, callLog?.id);
        updateUserStatusAfterEndCall(call)
        cleanDevice(twilioDevice, processor, call)
      });
      call.on("error", (error) => {
        console.log("An error has occurred: ", error);
        if (error.name == "AcquisitionFailedError") {
          alert("Call Failed. Make sure you have a microphone and sound connected and then try again.")
        }
        cleanDevice(twilioDevice, processor, call)
      });
      call.on("reject", () => {
        console.log("The call was rejected.");
        cleanDevice(twilioDevice, processor, call)
      });
      call.on("accept", async (call) => {

        const callLogData = { CallID: call.parameters.CallSid, CallDirection: "outgoing", PhoneNumber: to, DateTimeProcessed: new Date(), DateTimeAnswered: new Date(), AgentEmail: agentEmail }
        callLog = await CallLogService.add(callLogData)

        setCalls((prevCalls) => {
          return prevCalls.map((c) => c.call.parameters.CallSid == call.parameters.CallSid ? { ...c, callStatus: "Active", callLogID: callLog?.id } : c)
        });

        devices.current.set(call.parameters.CallSid, twilioDevice)  

        if(userStatusRef.current == "Online"){
          await UserLogService.add({ UserEmail: user.email, Status: "Busy", LogDate: new Date() })
          setUserStatus("Busy")
        }
      });


      addCall({ call, callStatus: "Outgoing", audioProcessor: processor, callLogID: 0 });


    } catch (err) {
      console.log("error making outgoing call")
    }


  };

  // Function to accept an incoming call
  const acceptCall = (call) => {
    call?.accept();    
  };


  const muteCall = (call, shouldMute, updateStatus = true) => {
    call?.mute(shouldMute);

    if (updateStatus) {
      updateCallStatus(call, shouldMute ? "Muted" : "Active")
    }
  };

  // Function to reject an incoming call
  const rejectCall = (call, callLogID) => {
    call?.reject();
    removeCall(call);

    if (callLogID) {
      logCallEndTime(callLogID)
    }

  };

  // Function to end an active call
  const endCall = (call, callLogID) => {
    call?.disconnect();
    removeCall(call);

    if (callLogID) {
      logCallEndTime(callLogID)
    }

  };

  const updateUserStatusAfterEndCall = (call) => {
    const numCurrCalls = callsRef.current?.filter(c => c.call.parameters.CallSid != call.parameters.CallSid)?.length

    if (userStatusRef.current == "Busy" && numCurrCalls == 0) {
      UserLogService.add({ UserEmail: user.email, Status: "Online", LogDate: new Date() })
      setUserStatus("Online")
    }
  }

  const logCallEndTime = async (callLogID) => {
    const callLog = await CallLogService.get(callLogID)
    await CallLogService.update({ ...callLog, DateTimeEnded: new Date() })
  }

  //Function to find active call
  const getActiveCall = () => {
    const activeCall = calls.filter(callData => callData.callStatus == "Active")[0]  
        return activeCall
  }

  const putAllActiveCallsOnHold = () => {
    calls.forEach((callData) => {
      if (callData.callStatus == "Active") {
        holdCall(callData.call, true)
      }
    }
    )
  }


  const holdCall = async (call, shouldHold) => {

    //block my sound from going to user - play message or mute my mic
    const callDevice = devices.current.get(call.parameters.CallSid)
    const processor = calls.find(c => c.call.parameters.CallSid == call.parameters.CallSid)?.audioProcessor

    if (callDevice && processor) {
      try {
        if (shouldHold) {
          await callDevice.audio.addProcessor(processor)
        } else {
          await callDevice.audio.removeProcessor(processor)
        }
      } catch {
        //audio issue
      }
    } else {
      muteCall(call, shouldHold, false)  
    }

    //block user sounds from coming in  Or  allow user sounds to come in again
    call.getRemoteStream().getAudioTracks().forEach(track => track.enabled = !shouldHold)


    updateCallStatus(call, shouldHold ? "OnHold" : "Active")

  }

  const updateCallStatus = (call, status) => {
    setCalls((prevCalls) => {
      return prevCalls.map((c) => c.call.parameters.CallSid == call.parameters.CallSid ? { ...c, callStatus: status } : c)

    });
  }

  //Function to create new device to accept incoming call
  const acceptIncomingCall = async (incomingCall, agentEmail) => {
    try {
      const response = await TwilioService.token();
      const twilioDevice = new Device(response.token, {
        codecPreferences: ["opus", "pcmu"],
      });


      const call = await twilioDevice.connect({ connectToken: incomingCall.call.connectToken })
      const processor = new BackgroundAudioProcessor()


      call.on("cancel", () => {
        console.log("The call has been canceled.");
        endCall(call, incomingCall.callLogID);
        updateUserStatusAfterEndCall(call)
        cleanDevice(twilioDevice, processor, call)
      });
      call.on("disconnect", (call) => {
        console.log("The call has been disconnected.");
        endCall(call, incomingCall.callLogID);
        updateUserStatusAfterEndCall(call)
        cleanDevice(twilioDevice, processor, call)
      });
      call.on("error", (error) => {
        console.log("An error has occurred: ", error);
        if (error.name == "AcquisitionFailedError") {
          alert("Call Failed. Make sure you have a microphone and sound connected and then try again.")
        }
        cleanDevice(twilioDevice, processor, call)
      });
      call.on("reject", () => {
        console.log("The call was rejected. Create Call");
        cleanDevice(twilioDevice, processor, call)
      });

      if(incomingCall.callLogID){
            const callLog = await CallLogService.get(incomingCall.callLogID)
            await CallLogService.update({ ...callLog, DateTimeAnswered: new Date(), AgentEmail: agentEmail })
      }

      setCalls((prevCalls) => {
        return prevCalls.map((c) => c.call.parameters.CallSid == incomingCall.call.parameters.CallSid ? { ...c, call: call, callStatus: "Active", audioProcessor: processor } : c)
      });

      devices.current.set(call.parameters.CallSid, twilioDevice)

      return call

    } catch (err) {
      console.log("error excepting call")
      removeCall(incomingCall.call)
    }
  }

  const cleanDevice = (device, processor, call) => {
    try {
      device.audio.removeProcessor(processor)
    } catch {
      //console.log("no processor to remove")
    }

    device.destroy();
    devices.current.delete(call.parameters.CallSid)
  }

  return {
    makeOutgoingCall,
    acceptCall,
    rejectCall,
    endCall,
    muteCall,
    addCall,
    getActiveCall,
    acceptIncomingCall,
    holdCall,
    putAllActiveCallsOnHold
  };
};
