import React, { useState, useEffect } from "react";
import PanelHeader from "components/PanelHeader/PanelHeader.jsx";
import { Row, Col, Button, Modal, ModalBody, ModalFooter, Card, CardHeader, CardTitle, CardBody } from "reactstrap";
import { useHistory } from "react-router-dom";
import { useAuth0 } from "react-auth0-spa";
import CallService from "core/api/CallService";
import UserList from "./UserList";
import ReactTable from "components/ReactTable/ReactTable.tsx";
import SoftphoneV1 from "components/Phone/V1/SoftphoneV1";


export default function CallCenter() {
    const [currentCalls, setCurrentCalls] = useState([])
    const [callTaken, setCallTaken] = useState(false);

    const { user } = useAuth0();
    const history = useHistory();


    const takeCall = async (id, status) => {
        if (status == "answered") {
            openCallReport(id)
        } else {
            const data = await CallService.get(id);
            if (data.status == "received") {
                await CallService.update({ ...data, status: "answered", UserEmail: user.email, DateTimeAnswered: new Date() })
                openCallReport(id)
            }
            else {
                setCallTaken(true)
            }
        }
    }

    const openCallReport = (id) => {
        // window.open(`/admin/call/${id}`, "_blank")
        history.push(`call/${id}`)
    }


    useEffect(() => {
        async function fetchData() {
            const currentCalls = await CallService.getCurrentCalls()
            setCurrentCalls(currentCalls.model)

        }

        fetchData();
        const interval = setInterval(() => {
            fetchData()
        }, 30000)

        return () => { clearInterval(interval) }
    }, []) 


    return (
        <>
            <PanelHeader content={
                <div className="header text-center">
                    <h2 className="title">
                        Call Center
                    </h2>
                </div>}
            />
            <div className="content" style={{ paddingTop: 30 }}>
             
                <Modal isOpen={callTaken} centered={true} >
                    <ModalBody style={{ justifyContent: "center", alignContent: "center", textAlign: "center" }} >
                        <div className="title">This Call Was Taken</div>
                        <i className="las la-phone-volume" style={{ fontSize: 50 }}></i>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={() => setCallTaken(false)} style={{ margin: "auto", display: "block" }} >OK</Button>
                    </ModalFooter>
                </Modal>

                <SoftphoneV1 />
                <Row>
                    <Col xs={12} md={12}>
                        <Card>
                            <CardHeader>
                                <CardTitle tag="h4">Current Calls</CardTitle>
                            </CardHeader>
                            <CardBody>
                                <ReactTable
                                    data={currentCalls}
                                    columns={[

                                        {
                                            header: "Phone Number",
                                            accessorKey: "elevatorPhoneNumber",
                                        },
                                        {
                                            header: "Date Received",
                                            accessorKey: "dateTimeReceived",
                                            accessorFn: row => { return new Date(row.dateTimeReceived) },
                                            filterFn: 'filterDate',
                                        },
                                        {
                                            header: "Elevator",
                                            accessorKey: "elevatorName",
                                        },
                                        {
                                            header: "Customer",
                                            accessorKey: "customerName",
                                        },
                                        {
                                            header: "Building",
                                            accessorKey: "buildingName",
                                        },
                                        {
                                            id: "takeCall",
                                            cell: ({ row }) => (
                                                <>
                                                    {row.original.status == "answered" && row.original.userEmail != user.email ?
                                                        <div>
                                                            <div>Call Taken By :</div>
                                                            <div>{row.original.userEmail}</div>
                                                        </div>
                                                        : 

                                                        <Button color="primary" style={{ margin: 0 }}
                                                            onClick={(event) => { takeCall(row.original.id, row.original.status); event.stopPropagation() }}
                                                        >
                                                            {row.original.status == "answered" ? "Continue Call" : "Take Call"}
                                                        </Button>
                                                    }

                                                </>
                                            ),

                                        }

                                    ]}
                                    fetchData={() => { }}
                                    defaultPageSize={100}
                                    getTrProps={() => { }}
                                />

                            </CardBody>
                        </Card>
                    </Col>
                </Row>

                <UserList title="Agents Online" type="active" />
            </div>
        </>


    )
}

