import Dashboard from "views/Dashboard/Dashboard";

import ListCustomers from "views/Customers/List";
import EditCustomer from "views/Customers/Edit";
import ViewCustomer from "views/Customers/View";

import ListBuildings from "views/Buildings/List";
import EditBuilding from "views/Buildings/Edit";
import ViewBuilding from "views/Buildings/View";

import ListDevices from "views/Devices/List";
import EditDevice from "views/Devices/Edit";
import ViewDevice from "views/Devices/View";
import Elevators from "views/Elevators";
import ViewAlert from "views/Alerts/View";

import CallCenter from "views/CallCenter/CallCenter";
import ElevatorReport from "views/Forms/ElevatorReport";

import ListReports from "views/Reports/List";
import ViewReport from "views/Reports/View";

import ListElevatorCompanies from "views/ElevatorCompanies/List"
import EditElevatorCompany from "views/ElevatorCompanies/Edit"

import ListUserLogs from "views/UserLogs/List"

import ListCallLogs from "views/CallLogs/List"

let routes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: "las la-stream",
    component: Dashboard,
    layout: "/admin",
  },
  {
    path: "/customers",
    name: "Customers",
    state: "openPages",
    icon: "now-ui-icons users_single-02",
    component: ListCustomers,
    mini: "CU",
    layout: "/admin",
    exact: true,
  },
  {
    path: "/customers/add",
    name: "Add Customer",
    component: EditCustomer,
    layout: "/admin",
    invisible: true,
    exact: true,
  },
  {
    path: "/customers/:id",
    name: "Customer",
    component: ViewCustomer,
    layout: "/admin",
    invisible: true,
    exact: true,
  },
  {
    path: "/customers/:id/edit",
    name: "Edit Customer",
    component: EditCustomer,
    layout: "/admin",
    invisible: true,
    exact: true,
  },
  {
    path: "/buildings",
    name: "Buildings",
    mini: "BG",
    component: ListBuildings,
    icon: "las la-city",
    layout: "/admin",
    exact: true,
  },
  {
    path: "/buildings/:id",
    name: "Buildings",
    component: ViewBuilding,
    layout: "/admin",
    invisible: true,
    exact: true,
  },
  {
    path: "/buildings/:customerId/add",
    name: "Buildings",
    component: EditBuilding,
    layout: "/admin",
    invisible: true,
    exact: true,
  },
  {
    path: "/buildings/:id/edit",
    name: "Buildings",
    component: EditBuilding,
    layout: "/admin",
    invisible: true,
    exact: true,
  },

  {
    path: "/elevators",
    name: "Elevators",
    mini: "DE",
    icon: "las la-columns",
    component: Elevators.List,
    layout: "/admin",
    exact: true,
  },
  {
    path: "/elevators/:id",
    name: "Elevators",
    component: Elevators.View,
    layout: "/admin",
    invisible: true,
    exact: true,
  },
  {
    path: "/elevators/:customerId|:buildingId/add",
    name: "Elevators",
    component: Elevators.Edit,
    layout: "/admin",
    invisible: true,
    exact: true,
  },
  {
    path: "/elevators/:id/edit",
    name: "Elevators",
    component: Elevators.Edit,
    layout: "/admin",
    invisible: true,
    exact: true,
  },

  {
    path: "/devices",
    name: "Devices",
    mini: "DE",
    icon: "now-ui-icons tech_mobile",
    component: ListDevices,
    layout: "/admin",
    invisible: true,
    exact: true,
  },
  {
    path: "/devices/:id",
    name: "Devices",
    component: ViewDevice,
    layout: "/admin",
    invisible: true,
    exact: true,
  },
  {
    path: "/devices/:customerId|:buildingId/add",
    name: "Devices",
    component: EditDevice,
    layout: "/admin",
    invisible: true,
    exact: true,
  },
  {
    path: "/devices/:id/edit",
    name: "Devices",
    component: EditDevice,
    layout: "/admin",
    invisible: true,
    exact: true,
  },
  {
    path: "/alerts/:id",
    name: "Alert",
    component: ViewAlert,
    layout: "/admin",
    invisible: true,
    exact: true,
  },
  {
    path: "/call",
    name: "Elevator Report",
    component: ElevatorReport,
    layout: "/admin",
    invisible: true,
    exact: true,
  },
  {
    path: "/call/:id",
    name: "Elevator Report",
    component: ElevatorReport,
    layout: "/admin",
    invisible: true,
    exact: true,
  },
  {
    path: "/elevatorcompanies",
    name: "Elevator Companies",
    mini: "EC",
    icon: "las la-user-friends",
    component: ListElevatorCompanies,
    layout: "/admin",
    exact: true,
  },
  {
    path: "/elevatorcompanies/add",
    name: "Add Elevator Company",
    component: EditElevatorCompany,
    layout: "/admin",
    invisible: true,
    exact: true,
  },
  {
    path: "/elevatorcompanies/:id",
    name: "Edit Elevator Company",
    component: EditElevatorCompany,
    layout: "/admin",
    invisible: true,
    exact: true,
  },
  {
    path: "/callcenter",
    name: "Call Center",
    icon: "las la-phone-volume",
    component: CallCenter,
    layout: "/admin",
    exact: true
  },
  {
    path: "/reports",
    name: "Call Reports",
    mini: "RP",
    component: ListReports,
    icon: "las la-file-alt",
    layout: "/admin",
    exact: true,
  },
  {
    path: "/reports/:id",
    name: "Reports",
    component: ViewReport,
    layout: "/admin",
    invisible: true,
    exact: true,
  },
  {
    path: "/calllogs",
    name: "Call Logs",
    mini: "CL",
    component: ListCallLogs,
    icon: "las la-phone-square",
    layout: "/admin",
    exact: true
  },
  {
    path: "/userlogs",
    name: "User Status Logs",
    mini: "UL",
    component: ListUserLogs,
    icon: "las la-clipboard-list",
    layout: "/admin",
    exact: true
  }

];

export default routes;
