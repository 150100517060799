import React from "react";
import { useParams } from "react-router-dom";
import { Field } from "formik";
import { ReactstrapInput, ReactstrapSelect } from "reactstrap-formik";
import * as Yup from "yup";
import { Row, Col } from "reactstrap";
import CrudEdit from "../Crud/Edit";
import service from "core/api/ElevatorService";

const DeviceSchema = Yup.object().shape({
  name: Yup.string().required("Required"),
  phoneNumber: Yup.string().required("Required"),
});

const Edit = () => {
  let { customerId, buildingId } = useParams();
  const initialData = {
    id: null,
    customerId,
    buildingId,
    name: "",
    phoneNumber: "",
  };

  return (
    <>
      <CrudEdit
        title="Elevators"
        initData={initialData}
        crudService={service}
        validationSchema={DeviceSchema}>
        <Row>
          <Col>
            <Field
              type="text"
              name="name"
              label="Name"
              component={ReactstrapInput}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Field
              type="text"
              name="phoneNumber"
              label="Phone Number"
              component={ReactstrapInput}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Field
              type="text"
              name="serialNumber"
              label="Elevator Id"
              component={ReactstrapInput}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <label>
              Is Billing
              <Field
                type="checkbox"
                name="isBilling"
                component={ReactstrapInput}
              />
            </label>
          </Col>
        </Row>
        <Row>
          <Col>
            <Field
              type="datetime-local"
              name="installationDate"
              label="Installation Date"
              component={ReactstrapInput}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Field
              type="text"
              name="location"
              label="Device Location"
              component={ReactstrapInput}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Field
              type="text"
              name="boardSerialNumber"
              label="Phone Board S/N"
              component={ReactstrapInput}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Field
              name="deviceType"
              label="Device Type"
              as="select"
              component={ReactstrapSelect}
              inputprops={{
                name: "deviceType",
                id: "deviceType",
                options: ["Elevator", "Phone", "Intercom", "Pool Phone"],
                defaultOption: "Elevator",
              }}></Field>
          </Col>
        </Row>
        <Row>
          <Col>
            <Field
              type="text"
              name="motorRoomLocation"
              label="Motor Room Location"
              component={ReactstrapInput}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <label>
              <Field
                type="checkbox"
                name="onlyLineTest"
                component={ReactstrapInput}
              />
              Only Line Test
            </label>
          </Col>
          <Col>
            <label>
              <Field
                type="checkbox"
                name="doNotCall"
                component={ReactstrapInput}
              />
              Don't call device
            </label>
          </Col>
          <Col>
            <label>
              <Field
                type="checkbox"
                name="doNotCheckOnlineStatus"
                component={ReactstrapInput}
              />
              Don't check online status
            </label>
          </Col>
        </Row>
        <Row>
          <Col>
            <Field
              type="text"
              name="callPauseLength"
              label="Call Pause Length"
              component={ReactstrapInput}
            />
          </Col>
        </Row>
        <Row style={{ marginTop: 10 }}>
          <Col>
            <label style={{ fontSize: 16 }}>
              <Field
                type="checkbox"
                name="callCenterEnabled"
              />
              {"   Call Center Enabled"}
            </label>
          </Col>
        </Row>

      </CrudEdit>
    </>
  );
};

export default Edit;
