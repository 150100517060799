export const cleanPhoneNumber = (phoneNumber) => {
    let newNumber = phoneNumber.replaceAll("(", "").replaceAll(")", "").replaceAll(" ", "").replaceAll("-", "").trim()
    if (!newNumber.startsWith("+1")) {
        if (!newNumber.startsWith("1")) {
            newNumber = "+1" + newNumber
        } else {
            newNumber = "+" + newNumber
        }
    }

    return newNumber
}



