import { call } from "core/UrlEndpoints";
import CrudService from "./CrudService";
import { fetchDataJson } from "../FetchApi";

class CallService extends CrudService {
  constructor() {
    super(call);
  }

  async getIncomingCallsForUser(userEmail) {
    return fetchDataJson(`${call}/GetIncomingCallsForUser?userEmail=${userEmail}`, {
      method: "GET",
    });
  }


  async getCurrentCalls() {
    return fetchDataJson(`${call}/GetCurrentCalls`, {
      method: "GET",
    });

  }

  async getCurrentReportsForElevator(id) {
    return fetchDataJson(`${call}/GetCurrentReportsForElevator/${id}`, {
      method: "GET",
    });
  }

  async GetByExternalCallID(id) {
    return fetchDataJson(`${call}/GetByExternalCallID/${id}`, {
      method: "GET",
    });
  }


}

export default new CallService();
