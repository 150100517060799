import React, { useState } from 'react';

const Switch = ({ value, onChange, trueText, FalseText }) => {


    return (
        <div className={`switch ${value ? 'switch-on' : 'switch-off'}`} onClick={() => { onChange() }}>
            <div className="switch-handle"></div>
            <span className="switch-text">{value ? trueText : FalseText}</span>

        </div>
    );
};

export default Switch;